'use strict';
import { environment } from '../environments/environment';

export const MINUTE = 1000 * 60;
export const HOUR = MINUTE * 60;

export const DEFAULT_PRODUCTION_DOMAIN_NAME = 'app';

// NOTE: PLEASE DO NOT CHANGE WITHOUT COMMUNICATING WITH ALL DEVELOPER WORKING ON THE FRONTEND, BOTH AT LEVERX AND CERTEMY
export const api_path = `${window.location.protocol}//${getHostName()}:${environment.port}/api`;
export const authz_path = `${window.location.protocol}//${getHostName()}:${environment.port}/authz`;
export const psv_path = `${window.location.protocol}//${getHostName()}:${environment.port}/psv`;

export function getHostName(): string {
  if (environment.name === 'Local') {
    return window.location.hostname;
  }
  return environment.name === 'Production' ? window.location.hostname : `${environment.name.toLowerCase()}.certemy.com`;
}
