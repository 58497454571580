import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { AppModule } from './app/app.module';
import {
  DEFAULT_CSS_VARIABLES_OBJECT_VALUE,
  updateCssVariables,
} from 'features/organization-settings/platform-branding/platform-branding.helpers';

const squarePaymentScript = document.createElement('script');
squarePaymentScript.src = environment.SQUARE_URL;
squarePaymentScript.setAttribute('async', '');
document.head.appendChild(squarePaymentScript);

// referral lib
if (environment.REFERRAL_URL && environment.useReferral) {
  const referralScript = document.createElement('script');
  referralScript.src = environment.REFERRAL_URL;
  referralScript.setAttribute('async', '');
  document.head.appendChild(referralScript);
}

if (environment.production) {
  const googleAnalyticsScript = document.createElement('script');
  const dataLayerScript = document.createElement('script');
  googleAnalyticsScript.src = `https://www.googletagmanager.com/gtag/js?id=UA-164887678-1`;

  dataLayerScript.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'UA-164887678-1');
  `;

  document.head.appendChild(googleAnalyticsScript);
  document.head.appendChild(dataLayerScript);
  enableProdMode();
}

updateCssVariables(DEFAULT_CSS_VARIABLES_OBJECT_VALUE);

platformBrowserDynamic().bootstrapModule(AppModule);
