import { constants } from 'constants/constants';
import { ROLE_CODES, ROLE_NAMES } from 'constants/role.constants';
import { QueryParamInterface } from './interfaces';
import { EDUCATION_INSTANCE } from 'constants/education-step.constants';
import { HttpHeaders } from '@angular/common/http';
import { saveAs } from 'file-saver-es';
import { HttpError } from 'services/http/http-error.interface';
import _get from 'lodash-es/get';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Type } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import _isEqual from 'lodash-es/isEqual';
import _uniq from 'lodash-es/uniq';
import _size from 'lodash-es/size';
import _clone from 'lodash-es/clone';
import _isEmpty from 'lodash-es/isEmpty';
import _isObjectLike from 'lodash-es/isObjectLike';
import _isArray from 'lodash-es/isArray';
import _cloneDeep from 'lodash-es/cloneDeep';
import { PublicRegistry } from 'services/organization/organization.interface';

export const downloadFile = (fileData: ArrayBuffer, fileName: string, fileType?: string) => {
  const blob = new Blob([fileData], fileType ? { type: fileType } : null);
  saveAs(blob, fileName);
};

export const createFileUploadHeaders = () => {
  // Don't pass Content-Type in case if it's 'multipart/form-step'
  // In this case browser will set it by himself and add boundary to the header
  const headers = new HttpHeaders({ 'Content-Type': 'multipart/form-data' });

  return { headers };
};

export const generateGuid = () => {
  return Math.floor(Math.random() * Math.pow(10, 13));
};

export function convertToMap<T>(items: T[], key: string): { [key: string]: T } {
  return items.reduce((map, item) => {
    map[item[key]] = item;
    return map;
  }, {});
}

export const getStates = (): any[] => [
  { code: 'AL', name: 'Alabama' },
  { code: 'AK', name: 'Alaska' },
  { code: 'AS', name: 'American Samoa' },
  { code: 'AZ', name: 'Arizona' },
  { code: 'AR', name: 'Arkansas' },
  { code: 'AF', name: 'Armed Forces Africa' },
  { code: 'AA', name: 'Armed Forces Americas' },
  { code: 'AC', name: 'Armed Forces Canada' },
  { code: 'AE', name: 'Armed Forces Europe' },
  { code: 'AM', name: 'Armed Forces Middle East' },
  { code: 'AP', name: 'Armed Forces Pacific' },
  { code: 'CA', name: 'California' },
  { code: 'CO', name: 'Colorado' },
  { code: 'CT', name: 'Connecticut' },
  { code: 'DE', name: 'Delaware' },
  { code: 'DC', name: 'District of Columbia' },
  { code: 'FM', name: 'Federated States Of Micronesia' },
  { code: 'FL', name: 'Florida' },
  { code: 'GA', name: 'Georgia' },
  { code: 'GU', name: 'Guam' },
  { code: 'HI', name: 'Hawaii' },
  { code: 'ID', name: 'Idaho' },
  { code: 'IL', name: 'Illinois' },
  { code: 'IN', name: 'Indiana' },
  { code: 'IA', name: 'Iowa' },
  { code: 'KS', name: 'Kansas' },
  { code: 'KY', name: 'Kentucky' },
  { code: 'LA', name: 'Louisiana' },
  { code: 'ME', name: 'Maine' },
  { code: 'MH', name: 'Marshall Islands' },
  { code: 'MD', name: 'Maryland' },
  { code: 'MA', name: 'Massachusetts' },
  { code: 'MI', name: 'Michigan' },
  { code: 'MN', name: 'Minnesota' },
  { code: 'MS', name: 'Mississippi' },
  { code: 'MO', name: 'Missouri' },
  { code: 'MT', name: 'Montana' },
  { code: 'NE', name: 'Nebraska' },
  { code: 'NV', name: 'Nevada' },
  { code: 'NH', name: 'New Hampshire' },
  { code: 'NJ', name: 'New Jersey' },
  { code: 'NM', name: 'New Mexico' },
  { code: 'NY', name: 'New York' },
  { code: 'NC', name: 'North Carolina' },
  { code: 'ND', name: 'North Dakota' },
  { code: 'MP', name: 'Northern Mariana Islands' },
  { code: 'OH', name: 'Ohio' },
  { code: 'OK', name: 'Oklahoma' },
  { code: 'OR', name: 'Oregon' },
  { code: 'PW', name: 'Palau' },
  { code: 'PA', name: 'Pennsylvania' },
  { code: 'PR', name: 'Puerto Rico' },
  { code: 'RI', name: 'Rhode Island' },
  { code: 'SC', name: 'South Carolina' },
  { code: 'SD', name: 'South Dakota' },
  { code: 'TN', name: 'Tennessee' },
  { code: 'TX', name: 'Texas' },
  { code: 'UT', name: 'Utah' },
  { code: 'VT', name: 'Vermont' },
  { code: 'VI', name: 'Virgin Islands' },
  { code: 'VA', name: 'Virginia' },
  { code: 'WA', name: 'Washington' },
  { code: 'WV', name: 'West Virginia' },
  { code: 'WI', name: 'Wisconsin' },
  { code: 'WY', name: 'Wyoming' },
];

export const getRedirectByRoleId = (roleId: ROLE_CODES) => {
  let redirect: string;

  switch (roleId) {
    case ROLE_CODES.SUPERADMIN:
    case ROLE_CODES.ORGANIZATION_ADMIN:
      redirect = '/organization';
      break;
    case ROLE_CODES.PROFESSIONAL:
      redirect = '/professional';
      break;
    case ROLE_CODES.IMPOSER:
      redirect = '/imposer';
      break;
    case ROLE_CODES.SUPERVISOR:
      redirect = '/supervisor';
      break;
    case ROLE_CODES.EMPLOYER:
      redirect = '/employer';
      break;
    case ROLE_CODES.PARTNER:
      redirect = '/partner';
      break;
    default:
      redirect = '/not-found';
  }

  return redirect;
};

export const getSectionByRoleId = (roleId) => {
  let section: string;

  switch (roleId) {
    case ROLE_CODES.SUPERADMIN:
    case ROLE_CODES.ORGANIZATION_ADMIN:
      section = 'organization';
      break;
    case ROLE_CODES.PROFESSIONAL:
      section = 'professional';
      break;
    case ROLE_CODES.SUPERVISOR:
      section = 'supervisor';
      break;
    case ROLE_CODES.IMPOSER:
      section = 'imposer';
      break;
    case ROLE_CODES.EMPLOYER:
      section = 'employer';
      break;
    case ROLE_CODES.PARTNER:
      section = 'partner';
      break;
    default:
      section = 'not-found';
  }

  return `/${section}`;
};

export const decodeEducItemStatus = (status_id) => {
  const statuses = constants.EDUCATIONAL_ITEM_STATUSES;
  for (const property in statuses) {
    if (statuses[property] === status_id) {
      return property;
    }
  }
};

export const validURL = (link) => {
  // Using same regex as at BE
  const strRegex =
    '^' +
    // protocol identifier
    '(?:(?:https?|ftp)://)' +
    // user:pass authentication
    '(?:\\S+(?::\\S*)?@)?' +
    '(?:' +
    // IP address exclusion
    // private & local networks
    '(?!(?:10|127)(?:\\.\\d{1,3}){3})' +
    '(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})' +
    '(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})' +
    // IP address dotted notation octets
    // excludes loopback network 0.0.0.0
    // excludes reserved space >= 224.0.0.0
    // excludes network & broacast addresses
    // (first & last IP address of each class)
    '(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])' +
    '(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}' +
    '(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))' +
    '|' +
    // host name
    '(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)' +
    // domain name
    '(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*' +
    // TLD identifier
    '(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))' +
    // TLD may end with dot
    '\\.?' +
    ')' +
    // port number
    '(?::\\d{2,5})?' +
    // resource path
    '(?:[/?#]\\S*)?' +
    '$';
  const regexp = new RegExp(strRegex, 'i');
  return regexp.test(link);
};

export const validPrice = (price) => {
  return /^[1-9][0-9]*?$/.test(price);
};

export const updateEducStepResponse = (step) => {
  const updatedData = step;
  if (step.course_instance) {
    updatedData.educItem = step.course_instance;
    updatedData.educItem.type = EDUCATION_INSTANCE.COURSE;
    updatedData.educItem.typeName = 'course';
    updatedData.educItem.status_name = decodeEducItemStatus(updatedData.educItem.status_id);
  }
  if (step.webinar_instance) {
    updatedData.educItem = step.webinar_instance;
    updatedData.educItem.type = EDUCATION_INSTANCE.WEBINAR;
    updatedData.educItem.typeName = 'webinar';
    updatedData.educItem.status_name = decodeEducItemStatus(updatedData.educItem.status_id);
  }
  return updatedData;
};

// Angular can not correctly handle relative navigation url
// so we need to set absolute path
// This helper is used to get section name to build absolute path
// Issue link: https://github.com/angular/angular/issues/13011
export const getSection = (url: string): string => {
  return url.split('/')[1];
};

export const getAllowedFields = (arrayOfObjects) => {
  return arrayOfObjects.filter((el) => !el.hasOwnProperty('permissions') || el.permissions);
};

export const createQueryParam = (name: string, value): QueryParamInterface => {
  let param;

  if (name === 'search' && !!value) {
    param = {
      name,
      value: encodeURIComponent(value),
    };
  } else {
    param = { name, value };
  }

  return param;
};

export const buildQueryParams = (params: QueryParamInterface[]) => {
  let queryParams = '?';
  for (const param of params) {
    if (param.value) {
      const value = typeof param.value === 'object' ? JSON.stringify(param.value) : param.value;
      queryParams += `&${param.name}=${value}`;
    }
  }
  return queryParams;
};

export const defaultQueryParams = ['search', 'limit', 'filters', 'orderId', 'orderType'];

export function mapToQueryParams<T extends { limit?: number; page_size?: number; page?: number; offset?: number; }>({
  filters,
  paramsArray = defaultQueryParams,
  createOffset = false,
}: {
  filters: T;
  paramsArray?: string[];
  createOffset: boolean;
}) {
  const paramsArrayClone = _clone(paramsArray);
  const filtersObjClone = _clone(filters);
  let offset = 0;
  filtersObjClone.limit = filtersObjClone.page_size;
  if (createOffset) {
    offset = --filtersObjClone.page * filtersObjClone.page_size;
    paramsArrayClone.push('offset');
    filtersObjClone.offset = offset;
  }
  const params = paramsArrayClone.map((key) => createQueryParam(key, filtersObjClone[key]));
  return buildQueryParams(params);
}

export const getHoursLabel = (hours, placeholder = '-') => {
  return typeof hours === 'number' ? `${hours} hour${hours !== 1 ? 's' : ''}` : placeholder;
};

export const getErrorMessage = (err: HttpError, defaultMessage: string = ''): string => {
  const errorDetails = _get(err, 'body.error_details', defaultMessage);
  if (typeof errorDetails === 'object' && errorDetails !== null && !_isEqual(errorDetails, {})) {
    const errorMessages = Object.values(errorDetails) || [];
    return errorMessages.map((message) => `${message} \n`).join('');
  } else {
    return typeof errorDetails === 'string' && (errorDetails as string).length
      ? errorDetails
      : _get(err, 'body.error_message', defaultMessage);
  }
};

export const setUserClipboardText = (element: HTMLInputElement) => {
  function copyToClipboard() {
    element.select();
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
  }

  if (element.hasAttribute('disabled')) {
    element.removeAttribute('disabled');
    copyToClipboard();
    element.setAttribute('disabled', '');
  } else {
    copyToClipboard();
  }
};

export const getDateInMiliseconds = (date?) => {
  if (!date) {
    return new Date().getTime();
  } else if (typeof date === 'string') {
    const d = new Date(date);
    // reset date hours and minutes
    return new Date(d.getFullYear(), d.getMonth(), d.getDate()).getTime();
  } else {
    return new Date(date.year, date.month - 1, date.day).getTime();
  }
};

export function sortBy(array: any[], objectField: string) {
  return array.sort((a, b) => {
    if (a[objectField] < b[objectField]) {
      return -1;
    } else if (a[objectField] > b[objectField]) {
      return 1;
    } else {
      return 0;
    }
  });
}

export function getMergedChildRouteParams(activatedRoute: ActivatedRouteSnapshot) {
  const { params } = activatedRoute;
  const firstChild = activatedRoute.firstChild;

  return firstChild ? { ...params, ...getMergedChildRouteParams(firstChild) } : { ...params };
}

export const getPlainInstance = <T>(someClass: Type<T>): T => {
  const instance = new someClass();
  Object.setPrototypeOf(instance, null);
  return instance;
};

export function convertFilterStructToQueryParam(obj: { page?: number; [key: string]: any }): string {
  const { page, ...restFilters } = obj;
  const pageStruct = pageToFilterStruct(page);
  return filterStructToQueryParam({
    ...pageStruct,
    ...restFilters,
  });
}

function filterStructToQueryParam(obj): string {
  const filterQuery = encodeURIComponent(JSON.stringify(obj));
  return `filters=${filterQuery}`;
}

export function pageToFilterStruct(page: number, limit = 10) {
  return page
    ? {
        limit,
        offset: (page - 1) * limit,
      }
    : {};
}

type FormElement = UntypedFormGroup | UntypedFormArray | UntypedFormControl;

export const getMergedFormErrors = (formElement: FormElement | null): ValidationErrors[] => {
  const children = Object.values(_get(formElement, 'controls', [])) as FormElement[];
  const errors = formElement.errors ? [formElement.errors] : [];
  return children.reduce((acc, el) => [...acc, ...getMergedFormErrors(el)], errors);
};

export function getDiffObjectKeys(obj1, obj2): string[] {
  return Object.keys(obj1).filter((key) => !_isEqual(obj1[key], obj2[key]));
}

export function getDiffObject(targetObj, defaultObj) {
  return Object.keys(targetObj).reduce((accum, key) => {
    if (!_isEqual(targetObj[key], defaultObj[key])) {
      accum[key] = targetObj[key];
    }

    return accum;
  }, {});
}

export function findAndReplaceURL(
  value: string | PublicRegistry.AttachmentValue[],
  url = '',
): string | PublicRegistry.AttachmentValue[] {
  if (_isArray(value)) {
    return value;
  }
  value = value as string;
  const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  const urlString = url && urlRegex.test(url) ? url : value;
  return (urlString || '').replace(
    urlRegex,
    (_url) => '<a href="' + _url + '" class="cm-link" target="_blank">' + value + '</a>',
  );
}

export function canMoveElement(elementId: number): boolean {
  const activeElement = document.activeElement;
  if (activeElement) {
    const activeElementDataId = activeElement.getAttribute('data-id');
    return +activeElementDataId !== elementId;
  }
  return true;
}

export function getNonUniqueEntities(entities) {
  const uniqueEntities = _uniq(entities);
  return uniqueEntities.filter((uniqueEntity) => {
    const entityQuantity = _size(entities.filter((email) => uniqueEntity === email));
    return entityQuantity > 1;
  });
}

export function arrayBufferToBase64(buffer: ArrayBuffer) {
  const bytes = new Uint8Array(buffer);
  const binary = bytes.reduce((sum, byte) => sum + String.fromCharCode(byte), '');

  return window.btoa(binary);
}

export function clearNullKey(obj: object): object {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value) {
      acc[key] = value;
    }
    return acc;
  }, {});
}

export function insertToPosition(array: any[], index: number, newItem: any) {
  if (_isEmpty(array) || !(index || index.toString()) || !(newItem || newItem.toString())) {
    return [];
  }

  return [...array.slice(0, index), newItem, ...array.slice(index)];
}

export function getRolesNamesForField(filedByRoleIds: number[]) {
  const text = filedByRoleIds.reduce((result, roleId) => (result += `${ROLE_NAMES[roleId]}, `), '');
  return text.slice(0, -2);
}

export function mapFieldTypeToSubType(field) {
  const typeId = _get(field, 'type.id', 0);
  let data;
  switch (typeId) {
    case constants.FORM_FIELD_TYPE_IDS_ADDITIONAL.EXAM:
      data = {
        type: {
          id: constants.FORM_FIELD_TYPE_IDS.SELECT,
        },
        subType: constants.SELECT_SUB_TYPES.EXAM,
      };
      break;
    case constants.FORM_FIELD_TYPE_IDS_ADDITIONAL.COUNTRY:
      data = {
        type: {
          id: constants.FORM_FIELD_TYPE_IDS.SELECT,
        },
        subType: constants.SELECT_SUB_TYPES.COUNTRY,
      };
      break;
    case constants.FORM_FIELD_TYPE_IDS_ADDITIONAL.STATE:
      data = {
        type: {
          id: constants.FORM_FIELD_TYPE_IDS.SELECT,
        },
        subType: constants.SELECT_SUB_TYPES.STATE,
      };
      break;
    case constants.FORM_FIELD_TYPE_IDS_ADDITIONAL.VALID_FROM:
      data = {
        type: {
          id: constants.FORM_FIELD_TYPE_IDS.DATE,
        },
        subType: constants.DATE_SUB_TYPES.VALID_FROM,
      };
      break;
    case constants.FORM_FIELD_TYPE_IDS_ADDITIONAL.VALID_TO:
      data = {
        type: {
          id: constants.FORM_FIELD_TYPE_IDS.DATE,
        },
        subType: constants.DATE_SUB_TYPES.VALID_TO,
      };
      break;
    case constants.FORM_FIELD_TYPE_IDS_ADDITIONAL.ZIP_CODE:
      data = {
        type: {
          id: constants.FORM_FIELD_TYPE_IDS.TEXT,
        },
        subType: constants.TEXT_SUB_TYPES.ZIP_CODE,
      };
      break;
    case constants.FORM_FIELD_TYPE_IDS_ADDITIONAL.PHYSICAL_MAILING_ADDRESS:
      data = {
        type: {
          id: constants.FORM_FIELD_TYPE_IDS.TEXT,
        },
        subType: constants.TEXT_SUB_TYPES.PHYSICAL_MAILING_ADDRESS,
      };
      break;
    case constants.FORM_FIELD_TYPE_IDS_ADDITIONAL.CITY:
      data = {
        type: {
          id: constants.FORM_FIELD_TYPE_IDS.TEXT,
        },
        subType: constants.TEXT_SUB_TYPES.CITY,
      };
      break;
    case constants.FORM_FIELD_TYPE_IDS_ADDITIONAL.LICENSE_VERIFICATION_STATUS:
      data = {
        type: {
          id: constants.FORM_FIELD_TYPE_IDS.SELECT,
        },
        subType: constants.SELECT_SUB_TYPES.LICENSE_VERIFICATION_STATUS,
      };
      break;
    case constants.FORM_FIELD_TYPE_IDS_ADDITIONAL.LICENSE_VERIFICATION_STATUS_NOTES:
      data = {
        type: {
          id: constants.FORM_FIELD_TYPE_IDS.TEXT,
        },
        subType: constants.TEXT_SUB_TYPES.LICENSE_VERIFICATION_STATUS_NOTES,
      };
      break;
    case constants.FORM_FIELD_TYPE_IDS_ADDITIONAL.LICENSE_RENEWAL_STATUS:
      data = {
        type: {
          id: constants.FORM_FIELD_TYPE_IDS.SELECT,
        },
        subType: constants.SELECT_SUB_TYPES.LICENSE_RENEWAL_STATUS,
      };
      break;
    case constants.FORM_FIELD_TYPE_IDS_ADDITIONAL.LICENSE_RENEWAL_STATUS_NOTES:
      data = {
        type: {
          id: constants.FORM_FIELD_TYPE_IDS.TEXT,
        },
        subType: constants.TEXT_SUB_TYPES.LICENSE_RENEWAL_STATUS_NOTES,
      };
      break;
    case constants.FORM_FIELD_TYPE_IDS_ADDITIONAL.LICENSE_TYPE:
      data = {
        type: {
          id: constants.FORM_FIELD_TYPE_IDS.SELECT,
        },
        subType: constants.SELECT_SUB_TYPES.LICENSE_TYPE,
      };
      break;
    case constants.FORM_FIELD_TYPE_IDS_ADDITIONAL.BIRTH_DATE:
      data = {
        type: {
          id: constants.FORM_FIELD_TYPE_IDS.DATE,
        },
        subType: constants.DATE_SUB_TYPES.BIRTH_DATE,
      };
      break;
    case constants.FORM_FIELD_TYPE_IDS_ADDITIONAL.SOCIAL_SECURITY_NUMBER:
      data = {
        type: {
          id: constants.FORM_FIELD_TYPE_IDS.TEXT,
        },
        subType: constants.TEXT_SUB_TYPES.SOCIAL_SECURITY_NUMBER,
      };
      break;
    case constants.FORM_FIELD_TYPE_IDS_ADDITIONAL.EMPLOYER_IDENTIFICATION_NUMBER:
      data = {
        type: {
          id: constants.FORM_FIELD_TYPE_IDS.TEXT,
        },
        subType: constants.TEXT_SUB_TYPES.EMPLOYER_IDENTIFICATION_NUMBER,
      };
      break;
    case constants.FORM_FIELD_TYPE_IDS_ADDITIONAL.NATIONAL_PROVIDER_IDENTIFIER:
      data = {
        type: {
          id: constants.FORM_FIELD_TYPE_IDS.TEXT,
        },
        subType: constants.TEXT_SUB_TYPES.NATIONAL_PROVIDER_IDENTIFIER,
      };
      break;
    case constants.FORM_FIELD_TYPE_IDS_ADDITIONAL.EXPIRATION_DATE:
      data = {
        type: {
          id: constants.FORM_FIELD_TYPE_IDS.DATE,
        },
        subType: constants.DATE_SUB_TYPES.EXPIRATION_DATE,
      };
      break;
    case constants.FORM_FIELD_TYPE_IDS_ADDITIONAL.LICENSE_NUMBER:
      data = {
        type: {
          id: constants.FORM_FIELD_TYPE_IDS.TEXT,
        },
        subType: constants.TEXT_SUB_TYPES.LICENSE_NUMBER,
      };
      break;
    case constants.FORM_FIELD_TYPE_IDS_ADDITIONAL.ISSUE_DATE:
      data = {
        type: {
          id: constants.FORM_FIELD_TYPE_IDS.DATE,
        },
        subType: constants.DATE_SUB_TYPES.ISSUE_DATE,
      };
      break;
    case constants.FORM_FIELD_TYPE_IDS_ADDITIONAL.ORIGINAL_ISSUE_DATE:
      data = {
        type: {
          id: constants.FORM_FIELD_TYPE_IDS.DATE,
        },
        subType: constants.DATE_SUB_TYPES.ORIGINAL_ISSUE_DATE,
      };
      break;
    case constants.FORM_FIELD_TYPE_IDS_ADDITIONAL.DISCIPLINARY_ISSUE:
      data = {
        type: {
          id: constants.FORM_FIELD_TYPE_IDS.SELECT,
        },
        subType: constants.SELECT_SUB_TYPES.DISCIPLINARY_ISSUE,
      };
      break;
    case constants.FORM_FIELD_TYPE_IDS_ADDITIONAL.DISCIPLINARY_URL:
      data = {
        type: {
          id: constants.FORM_FIELD_TYPE_IDS.TEXT,
        },
        subType: constants.TEXT_SUB_TYPES.DISCIPLINARY_URL,
      };
      break;
    default:
      data = {};
      break;
  }
  return { ...field, ...data };
}

export function mapFieldSubTypeToType(field) {
  const typeId = _get(field, 'type.id', 0);
  const subType = _get(field, 'subType', 0);
  let data;
  switch (typeId) {
    case constants.FORM_FIELD_TYPE_IDS.SELECT:
      switch (subType) {
        case constants.SELECT_SUB_TYPES.EXAM:
          data = {
            type: {
              id: constants.FORM_FIELD_TYPE_IDS_ADDITIONAL.EXAM,
            },
          };
          break;
        case constants.SELECT_SUB_TYPES.COUNTRY:
          data = {
            type: {
              id: constants.FORM_FIELD_TYPE_IDS_ADDITIONAL.COUNTRY,
            },
          };
          break;
        case constants.SELECT_SUB_TYPES.STATE:
          data = {
            type: {
              id: constants.FORM_FIELD_TYPE_IDS_ADDITIONAL.STATE,
            },
          };
          break;
        case constants.SELECT_SUB_TYPES.LICENSE_VERIFICATION_STATUS:
          data = {
            type: {
              id: constants.FORM_FIELD_TYPE_IDS_ADDITIONAL.LICENSE_VERIFICATION_STATUS,
            },
          };
          break;
        case constants.SELECT_SUB_TYPES.LICENSE_RENEWAL_STATUS:
          data = {
            type: {
              id: constants.FORM_FIELD_TYPE_IDS_ADDITIONAL.LICENSE_RENEWAL_STATUS,
            },
          };
          break;
        case constants.SELECT_SUB_TYPES.LICENSE_TYPE:
          data = {
            type: {
              id: constants.FORM_FIELD_TYPE_IDS_ADDITIONAL.LICENSE_TYPE,
            },
          };
          break;
        case constants.SELECT_SUB_TYPES.DISCIPLINARY_ISSUE:
          data = {
            type: {
              id: constants.FORM_FIELD_TYPE_IDS_ADDITIONAL.DISCIPLINARY_ISSUE,
            },
          };
          break;
        default:
          data = {};
      }
      break;
    case constants.FORM_FIELD_TYPE_IDS.DATE:
      switch (subType) {
        case constants.DATE_SUB_TYPES.VALID_FROM:
          data = {
            type: {
              id: constants.FORM_FIELD_TYPE_IDS_ADDITIONAL.VALID_FROM,
            },
          };
          break;
        case constants.DATE_SUB_TYPES.VALID_TO:
          data = {
            type: {
              id: constants.FORM_FIELD_TYPE_IDS_ADDITIONAL.VALID_TO,
            },
          };
          break;
        case constants.DATE_SUB_TYPES.BIRTH_DATE:
          data = {
            type: {
              id: constants.FORM_FIELD_TYPE_IDS_ADDITIONAL.BIRTH_DATE,
            },
          };
          break;
        case constants.DATE_SUB_TYPES.EXPIRATION_DATE:
          data = {
            type: {
              id: constants.FORM_FIELD_TYPE_IDS_ADDITIONAL.EXPIRATION_DATE,
            },
          };
          break;
        case constants.DATE_SUB_TYPES.ISSUE_DATE:
          data = {
            type: {
              id: constants.FORM_FIELD_TYPE_IDS_ADDITIONAL.ISSUE_DATE,
            },
          };
          break;
        case constants.DATE_SUB_TYPES.ORIGINAL_ISSUE_DATE:
          data = {
            type: {
              id: constants.FORM_FIELD_TYPE_IDS_ADDITIONAL.ORIGINAL_ISSUE_DATE,
            },
          };
          break;
        default:
          data = {};
      }
      break;
    case constants.FORM_FIELD_TYPE_IDS.TEXT:
      switch (subType) {
        case constants.TEXT_SUB_TYPES.ZIP_CODE:
          data = {
            type: {
              id: constants.FORM_FIELD_TYPE_IDS_ADDITIONAL.ZIP_CODE,
            },
          };
          break;
        case constants.TEXT_SUB_TYPES.PHYSICAL_MAILING_ADDRESS:
          data = {
            type: {
              id: constants.FORM_FIELD_TYPE_IDS_ADDITIONAL.PHYSICAL_MAILING_ADDRESS,
            },
          };
          break;
        case constants.TEXT_SUB_TYPES.CITY:
          data = {
            type: {
              id: constants.FORM_FIELD_TYPE_IDS_ADDITIONAL.CITY,
            },
          };
          break;
        case constants.TEXT_SUB_TYPES.LICENSE_VERIFICATION_STATUS_NOTES:
          data = {
            type: {
              id: constants.FORM_FIELD_TYPE_IDS_ADDITIONAL.LICENSE_VERIFICATION_STATUS_NOTES,
            },
          };
          break;
        case constants.TEXT_SUB_TYPES.LICENSE_RENEWAL_STATUS_NOTES:
          data = {
            type: {
              id: constants.FORM_FIELD_TYPE_IDS_ADDITIONAL.LICENSE_RENEWAL_STATUS_NOTES,
            },
          };
          break;
        case constants.TEXT_SUB_TYPES.SOCIAL_SECURITY_NUMBER:
          data = {
            type: {
              id: constants.FORM_FIELD_TYPE_IDS_ADDITIONAL.SOCIAL_SECURITY_NUMBER,
            },
          };
          break;
        case constants.TEXT_SUB_TYPES.EMPLOYER_IDENTIFICATION_NUMBER:
          data = {
            type: {
              id: constants.FORM_FIELD_TYPE_IDS_ADDITIONAL.EMPLOYER_IDENTIFICATION_NUMBER,
            },
          };
          break;
        case constants.TEXT_SUB_TYPES.NATIONAL_PROVIDER_IDENTIFIER:
          data = {
            type: {
              id: constants.FORM_FIELD_TYPE_IDS_ADDITIONAL.NATIONAL_PROVIDER_IDENTIFIER,
            },
          };
          break;
        case constants.TEXT_SUB_TYPES.LICENSE_NUMBER:
          data = {
            type: {
              id: constants.FORM_FIELD_TYPE_IDS_ADDITIONAL.LICENSE_NUMBER,
            },
          };
          break;
        case constants.TEXT_SUB_TYPES.DISCIPLINARY_URL:
          data = {
            type: {
              id: constants.FORM_FIELD_TYPE_IDS_ADDITIONAL.DISCIPLINARY_URL,
            },
          };
          break;
        default:
          data = {};
      }
      break;
    default:
      data = {};
      break;
  }
  return { ...field, ...data };
}

export function trimValuesInObject(obj) {
  const result = _cloneDeep(obj);
  if (_isObjectLike(result) && !_isArray(result)) {
    Object.keys(result).forEach((key) => {
      const value = result[key];
      result[key] = typeof value === 'string' ? value.trim() : value;
    });
  }
  return result;
}

export function updateRouteBreadcrumb(activatedRouteSnapshot: ActivatedRouteSnapshot, breadcrumb: string) {
  return (activatedRouteSnapshot.routeConfig.data = {
    ...activatedRouteSnapshot.routeConfig.data,
    breadcrumb,
  });
}

export function sortItemByName(arr = [], key = 'name') {
  return [...arr].sort(objectsLocaleCompare(key));
}

export function objectsLocaleCompare(paramName) {
  return (a, b) => {
    const valueA = _get(a, paramName) || '';
    const valueB = _get(b, paramName) || '';
    return valueA.localeCompare(valueB);
  };
}

export function localeCompare(a, b) {
  return (a || '').localeCompare(b);
}

export function replaceTextFromTemplate(text = '', content) {
  const arr = text.match(/{[\w.\[\]]+}/g);
  if (!arr) {
    return text;
  }
  arr.forEach((symbol) => {
    const regexp = new RegExp(symbol.replace(/\[/g, '\\['), 'g');
    text = text.replace(regexp, _get(content, symbol.substr(1, symbol.length - 2)));
  });
  return text;
}

export function getValuesArrayFromObjByKey(arr, keys, param = 'id') {
  return (keys || []).map((key) => arr[key[param]] || '');
}

export function replaceTemplateFromObject(text, param, obj, defaultValue = '') {
  if (typeof text !== 'string' || !param) {
    return text;
  }
  return text.replace(`{${param}}`, _get(obj, param, defaultValue));
}

const collator = new Intl.Collator('en', { numeric: true });
export function sortStringArray(array: string[]) {
  return array.sort((a, b) => collator.compare(a, b));
}
