import { SmartForm, SmartTable } from '@certemy/ui-components';
import { COLUMN_TYPE } from 'constants/status.constants';
import moment from 'moment-mini';

export enum WORKFLOW_MODULE_ID {
  CREDENTIAL = 1,
  TESTING_WORKFLOW = 2,
  COMPLIANCE = 3,
}

export enum DYNAMIC_FORM_ELEMENTS_PROVIDED_BY {
  ORGANIZATION = 1,
  TESTING_PROVIDER = 2,
  SYSTEM_PROVIDER = 3,
}

export enum DYNAMIC_FORM_ELEMENTS_OWN {
  ALIEN = 1,
  OWN,
}

export enum ONBOARDING_LICENSE_TYPES {
  UNIVERSAL = 1,
  ORGANIZATION_SPECIFIC = 2,
}

export const ONBOARDING_LICENSE_TYPES_NAMES = {
  [ONBOARDING_LICENSE_TYPES.UNIVERSAL]: 'Universal',
  [ONBOARDING_LICENSE_TYPES.ORGANIZATION_SPECIFIC]: 'Organization-Specific',
};

export const onboardingLicenseTypeOptions = [
  {
    id: ONBOARDING_LICENSE_TYPES.UNIVERSAL,
    name: ONBOARDING_LICENSE_TYPES_NAMES[ONBOARDING_LICENSE_TYPES.UNIVERSAL],
  },
  {
    id: ONBOARDING_LICENSE_TYPES.ORGANIZATION_SPECIFIC,
    name: ONBOARDING_LICENSE_TYPES_NAMES[ONBOARDING_LICENSE_TYPES.ORGANIZATION_SPECIFIC],
  },
];

export enum ONBOARDING_LICENSE_REQUIREMENT_OPTION {
  ANY_LICENSE = 0,
  ALL_REQUIRED_AND_ANY_ADDITIONAL = 1,
  ALL_REQUIRED_AND_ROLE_RELATED = 2,
}

export const ONBOARDING_LICENSE_REQUIREMENT_OPTION_NAMES = {
  [ONBOARDING_LICENSE_REQUIREMENT_OPTION.ANY_LICENSE]: 'User may enter any {license}',
  [ONBOARDING_LICENSE_REQUIREMENT_OPTION.ALL_REQUIRED_AND_ANY_ADDITIONAL]:
    'User MUST enter all required {license}(s) for their role, but may enter any additional {license}(s)',
  [ONBOARDING_LICENSE_REQUIREMENT_OPTION.ALL_REQUIRED_AND_ROLE_RELATED]:
    'User MUST enter all required {license}(s) for their role, and may ONLY enter additional {license}(s) associated with their role',
};

export const onboardingLicenseRequirementOptions = [
  {
    id: ONBOARDING_LICENSE_REQUIREMENT_OPTION.ANY_LICENSE,
    name: ONBOARDING_LICENSE_REQUIREMENT_OPTION_NAMES[ONBOARDING_LICENSE_REQUIREMENT_OPTION.ANY_LICENSE],
  },
  {
    id: ONBOARDING_LICENSE_REQUIREMENT_OPTION.ALL_REQUIRED_AND_ANY_ADDITIONAL,
    name:
      ONBOARDING_LICENSE_REQUIREMENT_OPTION_NAMES[
        ONBOARDING_LICENSE_REQUIREMENT_OPTION.ALL_REQUIRED_AND_ANY_ADDITIONAL
      ],
  },
  {
    id: ONBOARDING_LICENSE_REQUIREMENT_OPTION.ALL_REQUIRED_AND_ROLE_RELATED,
    name:
      ONBOARDING_LICENSE_REQUIREMENT_OPTION_NAMES[ONBOARDING_LICENSE_REQUIREMENT_OPTION.ALL_REQUIRED_AND_ROLE_RELATED],
  },
];

export enum ONBOARDING_PSV_VERIFICATION_OPTION {
  NOT_REQUIRED = 0,
  REQUIRED_FOR_ROLE_MANDATORY = 1,
  REQUIRED_FOR_ROLE_RELATED = 2,
  REQUIRED_FOR_ALL = 3,
}

export const ONBOARDING_PSV_VERIFICATION_OPTION_NAMES = {
  [ONBOARDING_PSV_VERIFICATION_OPTION.NOT_REQUIRED]: 'PSV verification not required',
  [ONBOARDING_PSV_VERIFICATION_OPTION.REQUIRED_FOR_ROLE_MANDATORY]:
    'PSV verification required for all {license}(s) that are required for user role ONLY',
  [ONBOARDING_PSV_VERIFICATION_OPTION.REQUIRED_FOR_ROLE_RELATED]:
    'PSV verification required for all {license}(s) that are associated with user role ONLY, regardless of whether required',
  [ONBOARDING_PSV_VERIFICATION_OPTION.REQUIRED_FOR_ALL]:
    'PSV verification required for all {license}(s) entered by user, independent of role',
};

export const onboardingPsvVerificationOptions = [
  {
    id: ONBOARDING_PSV_VERIFICATION_OPTION.NOT_REQUIRED,
    name: ONBOARDING_PSV_VERIFICATION_OPTION_NAMES[ONBOARDING_PSV_VERIFICATION_OPTION.NOT_REQUIRED],
  },
  {
    id: ONBOARDING_PSV_VERIFICATION_OPTION.REQUIRED_FOR_ROLE_MANDATORY,
    name: ONBOARDING_PSV_VERIFICATION_OPTION_NAMES[ONBOARDING_PSV_VERIFICATION_OPTION.REQUIRED_FOR_ROLE_MANDATORY],
  },
  {
    id: ONBOARDING_PSV_VERIFICATION_OPTION.REQUIRED_FOR_ROLE_RELATED,
    name: ONBOARDING_PSV_VERIFICATION_OPTION_NAMES[ONBOARDING_PSV_VERIFICATION_OPTION.REQUIRED_FOR_ROLE_RELATED],
  },
  {
    id: ONBOARDING_PSV_VERIFICATION_OPTION.REQUIRED_FOR_ALL,
    name: ONBOARDING_PSV_VERIFICATION_OPTION_NAMES[ONBOARDING_PSV_VERIFICATION_OPTION.REQUIRED_FOR_ALL],
  },
];

export const WORKFLOW_MODULE_NAMES = {
  [WORKFLOW_MODULE_ID.TESTING_WORKFLOW]: 'Testing Workflow',
  [WORKFLOW_MODULE_ID.CREDENTIAL]: 'Credential Info',
  [WORKFLOW_MODULE_ID.COMPLIANCE]: 'Compliance Workflow',
};

export const REQUIRE_VERIFICATION_LABEL = {
  DEFAULT: 'Require verification',
  FURTHER: 'Require further verification by organization',
  BEFORE_PROVIDER: 'Require verification before sending to testing provider',
};

export enum PHASE_REGISTRY_DATE_EXTENSION_OPTION_ID {
  BASED_ON_CUSTOM_FIELD = 35,
  BASED_ON_SPECIFIC_DATE,
  BASED_ON_REGISTRY_DATE_EXTENSION,
}

export const PHASE_REGISTRY_DATE_EXTENSION_OPTION_TOOLTIP = {
  [PHASE_REGISTRY_DATE_EXTENSION_OPTION_ID.BASED_ON_CUSTOM_FIELD]: `Custom Org Field: In this case, the registry value
 will be updated to a specific custom org field value (inclusive of the year).`,
  [PHASE_REGISTRY_DATE_EXTENSION_OPTION_ID.BASED_ON_SPECIFIC_DATE]: `Specific Date: In this case, the registry value will
 be updated to the specific date as calculated in the phase end date (please refer to “Phase End Date” help text for
 further information).`,
  [PHASE_REGISTRY_DATE_EXTENSION_OPTION_ID.BASED_ON_REGISTRY_DATE_EXTENSION]: `Duration: This will typically match the
 duration of the phase, but there is the ability to have the registry values extended with a different duration than the
 duration set for the phase. This registry duration extension will be added to the existing registry value (if one exists),
 or to the assignment date (if one does not exist).`,
};

export enum PHASE_REGISTRY_UPDATES_TIMING_OPTION {
  ALL_STEPS_COMPLETED = 1,
  ALL_STEPS_COMPLETED_AND_END_DATE_REACHED,
}

export enum PHASE_ACTION_TAKEN_OPTION {
  GENERATE_CERTIFICATE_UPDATE_REGISTRY = 1,
  GENERATE_CERTIFICATE_NO_UPDATE_REGISTRY = 2,
  NO_GENERATE_CERTIFICATE_NO_UPDATE_REGISTRY,
  NO_GENERATE_CERTIFICATE_UPDATE_REGISTRY,
}

export enum PHASE_ENDS_OPTION {
  ALL_REQUIRED_STEPS_COMPLETE = 1,
  END_DATE_REACHED_AND_ALL_REQUIRED_STEPS_COMPLETE,
  END_DATE_REACHED_ONLY,
  EARLIER_OF_ALL_REQUIRED_STEPS_COMPLETE_END_DATE_REACHED,
  LATER_OF_ALL_STEPS_COMPLETE_OR_END_DATE_REACHED = 38,
}

export const PHASE_ENDS_OPTION_TOOLTIP = {
  [PHASE_ENDS_OPTION.ALL_REQUIRED_STEPS_COMPLETE]: `When all steps are completed: This means that the user will be
  transitioned to the next phase only after all required steps are completed, regardless of the phase end date.`,
  [PHASE_ENDS_OPTION.END_DATE_REACHED_AND_ALL_REQUIRED_STEPS_COMPLETE]: `When phase end date reached:
  This means that the user will be transitioned to the “success” phase only after BOTH the phase end date is
  reached and all steps are completed. The user will be transitioned to the “failure” phase if the phase end date is
  reached before all steps are completed.`,
  [PHASE_ENDS_OPTION.END_DATE_REACHED_ONLY]: ``,
  [PHASE_ENDS_OPTION.EARLIER_OF_ALL_REQUIRED_STEPS_COMPLETE_END_DATE_REACHED]: ``,
  [PHASE_ENDS_OPTION.LATER_OF_ALL_STEPS_COMPLETE_OR_END_DATE_REACHED]: `The LATER of all steps complete or end date reached:
   In this option, the phase will end only when BOTH the end date is reached AND all required steps are complete.
   This is different from the first option because the end date also has to be reached to move to the next phase.
   This is different from the second option because all steps have to be completed even if the end date is reached
   in order to move to the next phase.`,
};

export enum PHASE_END_DATE_OPTION {
  FIXED_DATE = 5,
  FIXED_TIME_FROM_ASSIGNMENT = 6,
  FIXED_TIME_FROM_PREVIOUS_PHASE_COMPLETION = 7,
  FIXED_TIME_FROM_THE_DATE_OF_PRIOR_PHASE_COMPLETION = 8,
  BASED_ON_CUSTOM_ORG_FIELD = 27,
  BASED_ON_EXPIRATION_DATE_AND_DURATION = 28,
}

export const PHASE_END_DATE_OPTION_TOOLTIP = {
  [PHASE_END_DATE_OPTION.FIXED_DATE]: `Based on specific date: This refers to a fixed date (without the year). If someone
   is assigned to this phase BEFORE the specific date, the phase end date will be set to the date in the CURRENT year.
   If someone is assigned to this phase AFTER the specific date, the phase end date will be set to the date in the
   FOLLOWING year.`,
  [PHASE_END_DATE_OPTION.FIXED_TIME_FROM_ASSIGNMENT]: `Based on assignment & duration: This refers to a fixed amount of
  time someone will have to complete the phase once they have been assigned to the phase.`,
  [PHASE_END_DATE_OPTION.FIXED_TIME_FROM_PREVIOUS_PHASE_COMPLETION]: ``,
  [PHASE_END_DATE_OPTION.FIXED_TIME_FROM_THE_DATE_OF_PRIOR_PHASE_COMPLETION]: ``,
  [PHASE_END_DATE_OPTION.BASED_ON_CUSTOM_ORG_FIELD]: `Based on custom org field value: This refers to a fixed date
  (without the year) that has been set in a specific custom org field. If someone is assigned to this phase BEFORE the
  custom org field date, the phase end date will be set to the date in the CURRENT year. If someone is assigned to
  this phase AFTER the custom org field date, the phase end date will be set to the date in the FOLLOWING year.`,
  [PHASE_END_DATE_OPTION.BASED_ON_EXPIRATION_DATE_AND_DURATION]: `Based on date & duration: This refers to a fixed amount
  of time someone will have to complete the phase based on an existing date. For example, this may be an existing
  expiration date (for a license workflow) or a verification date (for a verification workflow). If this selection
  is made and no date exists, then the assignment date will be used as the starting date.`,
};

export enum SCHEDULING_RESPONSIBLE_OPTION {
  TESTING_PROVIDER = 19,
  ORGANIZATION = 20,
}

export enum REGISTRATION_RESPONSIBLE_OPTION {
  ORGANIZATION = 21,
  TESTING_PROVIDER = 22,
}

export enum SECTION_TYPE {
  DEFAULT = 0,
  LICENSE_ADDRESS_INFORMATION = 1,
}

export enum SCORING_RESPONSIBLE_DETERMINING_OPTION {
  TESTING_PROVIDER = 12,
  CERTEMY = 13,
  ORGANIZATION = 14,
}

export enum SCORING_RESPONSIBLE_OPTION {
  TESTING_PROVIDER = 9,
  CERTEMY = 10,
  ORGANIZATION = 11,
}

interface ExamThresholdValueData {
  examId: number;
  thresholdValue: number;
}

export enum SCORE_THRESHOLD_OPTION {
  ABSOLUTE_SCORE = 23,
  ABSOLUTE_PERCENTILE = 24,
}

export enum APPLYING_SCORE_VALUE_OPTION {
  FOR_EACH_EXAM = 25,
  GLOBAL_VALUE_TO_EACH_EXAM = 26,
}

export interface passFailOrganizationSettings {
  scoreThresholdOptionId: SCORE_THRESHOLD_OPTION;
  scoreValueApplyingOptionId: APPLYING_SCORE_VALUE_OPTION;
  globalScoreThresholdValue: number;
  examThresholdValuesData: ExamThresholdValueData[];
}

export const PROFESSIONAL_PAGE_TITLE = {
  DEFAULT: 'My Credentials',
  TESTING_WORKFLOWS: 'My Testing:',
  CERTIPROF: 'My Exams',
};

export const CERTEMY_TASKS_WORKFLOW_HEADERS_MAP: { [key: string]: SmartTable.Header } = {
  priority: { name: 'Priority', value: 'stepInstanceColorId', type: COLUMN_TYPE.STATUS_COLOR },
  organizationName: {
    name: 'Organization',
    value: 'organizationName',
    type: SmartTable.ColumnTypeId.STRING,
    sortable: true,
  },
  firstName: { name: 'First Name', value: 'firstName', type: SmartTable.ColumnTypeId.STRING, sortable: true },
  lastName: { name: 'Last Name', value: 'lastName', type: SmartTable.ColumnTypeId.STRING, sortable: true },
  credentialName: {
    name: 'Credential Name',
    value: 'credentialName',
    type: SmartTable.ColumnTypeId.STRING,
    sortable: true,
  },
  workflowSubTypeName: {
    name: 'Credential Type',
    value: 'workflowSubTypeName',
    type: SmartTable.ColumnTypeId.STRING,
    sortable: true,
  },
  workflowName: { name: 'Workflow Name', value: 'workflowName', type: SmartTable.ColumnTypeId.STRING, sortable: true },
  phaseName: { name: 'Phase Name', value: 'phaseName', type: SmartTable.ColumnTypeId.STRING, sortable: true },
  stepName: { name: 'Step Name', value: 'stepName', type: SmartTable.ColumnTypeId.STRING, sortable: true },
  stepTypeName: { name: 'Step Type', value: 'stepTypeName', type: SmartTable.ColumnTypeId.STRING, sortable: true },
  expirationDate: {
    name: 'Expiration Date',
    value: 'expirationDate',
    type: SmartTable.ColumnTypeId.STRING,
    sortable: true,
  },
  phaseEndDate: { name: 'Phase End Date', value: 'phaseEndDate', type: SmartTable.ColumnTypeId.STRING, sortable: true },
  stepDueDate: { name: 'Step Due Date', value: 'stepDueDate', type: SmartTable.ColumnTypeId.STRING, sortable: true },
  updatedAt: { name: 'Last Modified Date', value: 'updatedAt', type: SmartTable.ColumnTypeId.STRING, sortable: true },
};

export const TESTING_WORKFLOW_HEADERS_MAP: { [key: string]: SmartTable.Header } = {
  priority: { name: 'Priority', value: 'stepInstanceColorId', type: COLUMN_TYPE.STATUS_COLOR },
  firstName: { name: 'First Name', value: 'firstName', type: SmartTable.ColumnTypeId.STRING, sortable: true },
  lastName: { name: 'Last Name', value: 'lastName', type: SmartTable.ColumnTypeId.STRING, sortable: true },
  email: { name: 'E-mail', value: 'email', type: SmartTable.ColumnTypeId.STRING, sortable: true },
  // candidateName: { name: 'Candidate ID', value: 'candidateName', type: SmartTable.ColumnTypeId.STRING, sortable: true },
  workflowName: { name: 'Workflow Name', value: 'workflowName', type: SmartTable.ColumnTypeId.STRING, sortable: true },
  examName: { name: 'Exam Name', value: 'examName', type: SmartTable.ColumnTypeId.STRING, sortable: true },
  phaseName: { name: 'Phase', value: 'phaseName', type: SmartTable.ColumnTypeId.STRING, sortable: true },
  stepName: { name: 'Step', value: 'stepName', type: SmartTable.ColumnTypeId.STRING, sortable: true },
  dueDate: { name: 'Due Date', value: 'dueDate', type: SmartTable.ColumnTypeId.STRING, sortable: true },
  // { name: '#', value: 'hash', type: SmartTable.ColumnTypeId.STRING, sortable: true },
  updatedAt: { name: 'Last Modified', value: 'updatedAt', type: SmartTable.ColumnTypeId.STRING, sortable: true },
};

export const COMPLIANCE_WORKFLOW_HEADERS_MAP: { [key: string]: SmartTable.Header } = {
  priority: { name: 'Priority', value: 'stepInstanceColorId', type: COLUMN_TYPE.STATUS_COLOR },
  firstName: { name: 'First Name', value: 'firstName', type: SmartTable.ColumnTypeId.STRING, sortable: true },
  lastName: { name: 'Last Name', value: 'lastName', type: SmartTable.ColumnTypeId.STRING, sortable: true },
  credentialName: {
    name: 'Credential Name',
    value: 'credentialName',
    type: SmartTable.ColumnTypeId.STRING,
    sortable: true,
  },
  workflowSubTypeName: {
    name: 'Credential Type',
    value: 'workflowSubTypeName',
    type: SmartTable.ColumnTypeId.STRING,
    sortable: true,
  },
  workflowName: { name: 'Workflow Name', value: 'workflowName', type: SmartTable.ColumnTypeId.STRING, sortable: true },
  phaseName: { name: 'Phase Name', value: 'phaseName', type: SmartTable.ColumnTypeId.STRING, sortable: true },
  stepName: { name: 'Step Name', value: 'stepName', type: SmartTable.ColumnTypeId.STRING, sortable: true },
  stepTypeName: { name: 'Step Type', value: 'stepTypeName', type: SmartTable.ColumnTypeId.STRING, sortable: true },
  expirationDate: {
    name: 'Expiration Date',
    value: 'expirationDate',
    type: SmartTable.ColumnTypeId.STRING,
    sortable: true,
  },
  phaseEndDate: { name: 'Phase End Date', value: 'phaseEndDate', type: SmartTable.ColumnTypeId.STRING, sortable: true },
  stepDueDate: { name: 'Step Due Date', value: 'stepDueDate', type: SmartTable.ColumnTypeId.STRING, sortable: true },
  updatedAt: { name: 'Last Modified Date', value: 'updatedAt', type: SmartTable.ColumnTypeId.STRING, sortable: true },
};

export enum TransitionType {
  IN,
  OUT,
}

export enum UPDATE_REGISTRY_AND_GENERATE_FILE_OPTION_ID {
  GENERATE_CERTIFICATE_UPDATE_REGISTRY = 29,
  GENERATE_CERTIFICATE_NO_UPDATE_REGISTRY = 30,
  NO_GENERATE_CERTIFICATE_NO_UPDATE_REGISTRY = 31,
  NO_GENERATE_CERTIFICATE_UPDATE_REGISTRY = 32,
}

export const UPDATE_REGISTRY_AND_GENERATE_FILE_OPTION_TOOLTIP = {
  [UPDATE_REGISTRY_AND_GENERATE_FILE_OPTION_ID.GENERATE_CERTIFICATE_UPDATE_REGISTRY]: `Generate Certificate & Update
  Registry: A certificate will be generated, and the registry (expiration date, verification date, renewal date – depending
  on type of workflow) will be updated. This option should be selected when the Certemy platform will generate the official
  certificate number.`,
  [UPDATE_REGISTRY_AND_GENERATE_FILE_OPTION_ID.GENERATE_CERTIFICATE_NO_UPDATE_REGISTRY]: ``,
  [UPDATE_REGISTRY_AND_GENERATE_FILE_OPTION_ID.NO_GENERATE_CERTIFICATE_NO_UPDATE_REGISTRY]: `Do NOT Generate Certificate,
  Do NOT Update Registry: This should be used for workflows that have nothing to do with credentialing or compliance and
  which do not require any updates to the registry. These would typically be workflows to track the progress of miscellaneous tasks.`,
  [UPDATE_REGISTRY_AND_GENERATE_FILE_OPTION_ID.NO_GENERATE_CERTIFICATE_UPDATE_REGISTRY]: `Do NOT Generate Certificate &
  Update Registry: In this case, a third-party will be responsible for generating the actual certificate number, but you
  still would like to update the registry (expiration date, verification date, renewal date – depending on type of
  workflow) once all requirements have been met.`,
};

export enum REGISTRY_UPDATE_OPTION_ID {
  WHEN_ALL_STEPS_COMPLETED = 33,
  WHEN_ALL_STEPS_COMPLETED_AND_PHASE_END_DATE_REACHED = 34,
}

export const REGISTRY_UPDATE_OPTION_TOOLTIP = {
  [REGISTRY_UPDATE_OPTION_ID.WHEN_ALL_STEPS_COMPLETED]: `When all steps are completed: This means that the registry will
  be updated only after all required steps are completed, regardless of the phase end date.`,
  [REGISTRY_UPDATE_OPTION_ID.WHEN_ALL_STEPS_COMPLETED_AND_PHASE_END_DATE_REACHED]: `When phase end date reached AND all
  steps are completed: This means that the registry will be updated only after BOTH the phase end date is reached and
  all steps are completed. If the user does not complete the steps before the phase end date, the registry will NOT be updated.`,
};

export const VERIFICATION_STEP_ADDITIONAL_PARAMETERS_OPTIONS_ID = {
  PAUSE_ENABLE: 1,
  WAIT_FOR_OTHER_STEPS: 2,
  NO_ADDITIONAL_PARAMETERS: 3,
};

export const verificationStepAdditionalParametersOptions: SmartForm.FieldOption[] = [
  {
    id: VERIFICATION_STEP_ADDITIONAL_PARAMETERS_OPTIONS_ID.PAUSE_ENABLE,
    name: 'Allow Verifier to pause the credential',
  },
  {
    id: VERIFICATION_STEP_ADDITIONAL_PARAMETERS_OPTIONS_ID.WAIT_FOR_OTHER_STEPS,
    name: 'Set step as the last to be completed',
  },
  { id: VERIFICATION_STEP_ADDITIONAL_PARAMETERS_OPTIONS_ID.NO_ADDITIONAL_PARAMETERS, name: 'No additional parameters' },
];

export const next5YearDate = moment()
  .add(5, 'years')
  .toISOString();
