import { PolicyObj } from 'services/policy-permission/policy-permissions.service';

export enum LOGIN_STATUSES {
  INACTIVE_PROFILE = 'LOGIN_INACTIVE_ACCOUNT',
  FAIL = 'LOGIN_FAIL',
  MEMBER_CLICKS_ERROR = 'MEMBER_CLICKS_ERROR',
  MEMBER_CLICKS_VALIDATION_ERROR = 'MEMBER_CLICKS_VALIDATION_ERROR',
  SUCCESS = 'LOGIN_SUCCESS',
  CERTIFICATION_ALREADY_EXIST = 'CERTIFICATION_ALREADY_EXIST',
  INVALID_TOKEN = 'INVALID_TOKEN',
  TERMS_AND_CONDITIONS_NOT_ACCEPTED = 'TERMS_AND_CONDITIONS_NOT_ACCEPTED',
  TESTING_WORKFLOW_ALREADY_EXIST = 'TESTING_WORKFLOW_ALREADY_EXIST',
  CONFIRMATION_REQUIRED = 'CONFIRMATION_REQUIRED',
}

export const IGNORE_SESSION_URLS = ['/', '/not-found', '/forbidden'];
export const IGNORE_SESSION_URLS_REGEXP = [
  /^\/switch-profile\/.*/,
  /^\/entry\/.*/,
  /^\/organization\/assignments\/\d\/new\/professionals$/,
  /^\/organization\/assignments\/\d\/new\/info$/,
  /^\/public-registry\/.*/,
];

export type startUrlPoliciesKeys =
  | 'credentialing'
  | 'credentialingDashboard'
  | 'credentialingVerificationList'
  | 'credentialingPrimaryVerification'
  | 'testing'
  | 'testingWorkflowDashboard'
  | 'testingWorkflowVerificationList'
  | 'testingWorkflowPrimaryVerification'
  | 'testingWorkflowTodoVerification'
  | 'compliance'
  | 'complianceWorkflowDashboard'
  | 'complianceWorkflowVerificationList'
  | 'complianceWorkflowPrimaryVerification'
  | 'complianceWorkflowTodoVerification';

export type startUrlPermissionsType = Record<startUrlPoliciesKeys, boolean>;

export const START_URL_SETTINGS_POLICIES: Record<startUrlPoliciesKeys, PolicyObj> = {
  credentialing: { name: 'credentialing', url: 'credentialing/all/all', action: 'module_access' },
  credentialingDashboard: { name: 'credentialingDashboard', url: 'credentialing/dashboard/main', action: 'view' },
  credentialingVerificationList: {
    name: 'credentialingVerificationList',
    url: 'credentialing/verification_list/all',
    action: 'access',
  },
  credentialingPrimaryVerification: {
    name: 'credentialingPrimaryVerification',
    url: 'credentialing/primary_verification/all',
    action: 'access',
  },
  testing: { name: 'testing', url: 'testing/all/all', action: 'module_access' },
  testingWorkflowDashboard: { name: 'testingWorkflowDashboard', url: 'testing/dashboard/main', action: 'view' },
  testingWorkflowVerificationList: {
    name: 'testingWorkflowVerificationList',
    url: 'testing/verification_list/all',
    action: 'access',
  },
  testingWorkflowPrimaryVerification: {
    name: 'testingWorkflowPrimaryVerification',
    url: 'testing/primary_verification/all',
    action: 'access',
  },
  testingWorkflowTodoVerification: {
    name: 'testingWorkflowTodoVerification',
    url: 'testing/todo_verification/all',
    action: 'access',
  },
  compliance: { name: 'compliance', url: 'compliance/all/all', action: 'module_access' },
  complianceWorkflowDashboard: {
    name: 'complianceWorkflowDashboard',
    url: 'compliance/dashboard/main',
    action: 'view',
  },
  complianceWorkflowVerificationList: {
    name: 'complianceWorkflowVerificationList',
    url: 'compliance/verification_list/all',
    action: 'access',
  },
  complianceWorkflowPrimaryVerification: {
    name: 'complianceWorkflowPrimaryVerification',
    url: 'compliance/primary_verification/all',
    action: 'access',
  },
  complianceWorkflowTodoVerification: {
    name: 'complianceWorkflowTodoVerification',
    url: 'compliance/todo_verification/all',
    action: 'access',
  },
};

export const IFRAME_EXTERNAL_ID_PROVIDER_CHECK_VALUE = 'external_id_provider';
export const IFRAME_EXTERNAL_ID_PROVIDER_INIT_MESSAGE = 'external_id_provider_init';
