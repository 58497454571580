import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Selectors } from 'features/profiles-state/+state/profiles-state.state';
import { ROLE_CODES } from 'constants/role.constants';
import { AuthzService } from 'services/authz/authz.service';
import { Observable } from 'rxjs';
import { Permissions } from './permissions.interface';

export enum DISPLAY_LOCATIONS {
  ADVANCED = '1',
  MODULE = '2',
}

export enum DISPLAY_ON_UI {
  SHOW = 1,
  HIDE = 0,
}

export enum LOCATION_TYPE {
  TASK = 'task',
  ADVANCED = 'advanced',
  CALCULATED = 'calculated',
  GLOBAL = 'global',
  ALL = 'all',
  OVERWRITE = 'overwrite',
}

export enum PERMISSIONS {
  certificationAdd,
  certificationRemove,
  certificationEdit,
  certificationCopy,
  certificationViewAll,
  certificationViewOrganization,
  certificationViewMy,
  certificationStatusFilter,
  certificationIndustryFilter,
  certificationRegistryVisibility,

  userGroupsShown,
  userCustomFieldsShown,
  usersAddGroup,
  customFieldInstanceEdit,

  assignRegFormStep,

  profileSubscriptions,
  profileActivateSubscription,
  profileDeactivateSubscription,
  profileAddSubscription,
  profileDeleteSubscription,

  profileVerificationForms,
  profileEnableVerificationForm,

  testRegistrationReports,

  deleteUsersCertifications,
}

@Injectable()
export class PermissionsService {
  private roleId: number;

  private readonly ROLES = {
    [ROLE_CODES.SUPERADMIN]: [
      PERMISSIONS.certificationAdd,
      PERMISSIONS.certificationRemove,
      PERMISSIONS.certificationEdit,
      PERMISSIONS.certificationCopy,
      PERMISSIONS.certificationViewAll,
      PERMISSIONS.certificationViewOrganization,
      PERMISSIONS.certificationViewMy,
      PERMISSIONS.certificationStatusFilter,
      PERMISSIONS.certificationIndustryFilter,
      PERMISSIONS.certificationRegistryVisibility,

      PERMISSIONS.userGroupsShown,
      PERMISSIONS.userCustomFieldsShown,
      PERMISSIONS.usersAddGroup,

      PERMISSIONS.customFieldInstanceEdit,

      PERMISSIONS.profileSubscriptions,
      PERMISSIONS.profileActivateSubscription,
      PERMISSIONS.profileDeactivateSubscription,
      PERMISSIONS.profileAddSubscription,
      PERMISSIONS.profileDeleteSubscription,

      PERMISSIONS.profileVerificationForms,
      PERMISSIONS.profileEnableVerificationForm,

      PERMISSIONS.testRegistrationReports,

      PERMISSIONS.deleteUsersCertifications,
    ],
    [ROLE_CODES.ORGANIZATION_ADMIN]: [
      PERMISSIONS.certificationAdd,
      PERMISSIONS.certificationRemove,
      PERMISSIONS.certificationEdit,
      PERMISSIONS.certificationCopy,
      PERMISSIONS.certificationViewAll,
      PERMISSIONS.certificationViewOrganization,
      PERMISSIONS.certificationViewMy,
      PERMISSIONS.certificationStatusFilter,
      PERMISSIONS.certificationIndustryFilter,
      PERMISSIONS.certificationRegistryVisibility,

      PERMISSIONS.customFieldInstanceEdit,

      PERMISSIONS.userGroupsShown,
      PERMISSIONS.userCustomFieldsShown,
      PERMISSIONS.usersAddGroup,

      PERMISSIONS.profileSubscriptions,
      PERMISSIONS.profileActivateSubscription,
      PERMISSIONS.profileDeactivateSubscription,
      PERMISSIONS.profileAddSubscription,
      PERMISSIONS.profileDeleteSubscription,

      PERMISSIONS.profileVerificationForms,
      PERMISSIONS.profileEnableVerificationForm,

      PERMISSIONS.testRegistrationReports,
      PERMISSIONS.deleteUsersCertifications,
    ],
    [ROLE_CODES.IMPOSER]: [
      PERMISSIONS.certificationViewOrganization,
      PERMISSIONS.certificationStatusFilter,

      PERMISSIONS.assignRegFormStep,

      PERMISSIONS.profileSubscriptions,

      PERMISSIONS.testRegistrationReports,

      PERMISSIONS.deleteUsersCertifications,
    ],
    [ROLE_CODES.EMPLOYER]: [],
    [ROLE_CODES.PROFESSIONAL]: [PERMISSIONS.customFieldInstanceEdit],
  };

  constructor(private store: Store<any>, private authzService: AuthzService) {
    this.store.select(Selectors.currentProfile).subscribe(profile => {
      if (profile && profile.role_id) {
        this.roleId = profile.role_id;
      } else {
        this.roleId = -1;
      }
    });
  }

  hasPermissions(feature) {
    if (!this.roleId) {
      return false;
    }
    const hasPermissions = this.ROLES[this.roleId].indexOf(feature);
    return hasPermissions > -1;
  }

  getPermissionForGroupAndProfile(
    roleId: number,
    groupId: number,
    displayLocation: string,
    profileId?: number,
  ): Observable<any> {
    let url = `/v1/permission/ui?roleId=${roleId}&groupId=${groupId}&locationType=${displayLocation}`;
    if (profileId) {
      url = `${url}&profileId=${profileId}`;
    }
    return this.authzService.get(url);
  }

  getEnforcerModel(): Observable<Permissions.PolicyEnforcerModelResponse> {
    return this.authzService.get('/v1/policy/enforcer-model');
  }

  getPolicies(
    policyObject: string,
    policySubjectOverride?: Permissions.PolicyConfigOverride,
  ): Observable<Permissions.PolicyListResponse> {
    // setup the default url with the policyObject search
    let url = `/v1/policy/?policyObject=${policyObject}`;
    if (policySubjectOverride) {
      // provide role id override
      if (policySubjectOverride.roleIdOverride) {
        url = `${url}&roleIdOverride=${policySubjectOverride.roleIdOverride}`;
      }
      // provide the grouo id override
      if (policySubjectOverride.groupIdOverride) {
        url = `${url}&groupIdOverride=${policySubjectOverride.groupIdOverride}`;
      }
    }
    return this.authzService.get(url);
  }

  cachePoliciesFromSession(): Observable<Permissions.PolicyCacheFromSessionResponse> {
    return this.authzService.patch('/v1/policy/cache-from-session', {});
  }

  clearCacheForProfile(
    organizationId: number,
    profileId: number,
  ): Observable<Permissions.PolicyCacheFromSessionResponse> {
    return this.authzService.delete(`/v1/cache/${organizationId}/user-session`, {
      profileId,
    });
  }

  setProfilePolicyOverrides(payload: any): Observable<Permissions.PolicyEnforcerModelResponse> {
    return this.authzService.post('/v1/permission/ui', payload);
  }

  getAllOverrides(resourceTypeList: string): Observable<Permissions.AllOverridesResponse> {
    return this.authzService.get(`/v1/policy/overrides?resourceTypeList=${resourceTypeList}`);
  }

  getOrganizationOverrides(organizationId: number): Observable<Permissions.OrganizationOverridesResponse> {
    return this.authzService.get(`/v1/policy/organization/${organizationId}/overrides`);
  }

  updateOrganizationOverrides(
    organizationId: number,
    payload: Permissions.OverridePatch,
  ): Observable<Permissions.PatchOrganizationOverridesResponse> {
    return this.authzService.patch(`/v1/policy/organization/${organizationId}/overrides/change`, payload);
  }
}
